﻿// Write your JavaScript code.
import 'normalize.css/normalize.css'
import '../Styles/main.scss';

function init() {
    // Modify forms to disable all buttons on submit

    function disableButtonsAndInputs(form) {
        form.querySelectorAll('button, input[type=submit]').forEach(e => {
            e.disabled = true;
        });
    }

    document.querySelectorAll('form').forEach(e => {
        e.addEventListener('submit', (evt) => {
            disableButtonsAndInputs(evt.target);
        });
    });

    // Enable show password button
    const passwordIcon = document.getElementById('password-icon');
    const passwordInput = document.getElementById('password-input');
    if (passwordIcon && passwordInput) {
        passwordIcon.addEventListener('mousedown', function (event) {
            passwordInput.type = 'text';
        });
        passwordIcon.addEventListener('mouseup', function (event) {
            passwordInput.type = 'password';
        });
        passwordIcon.addEventListener('touchstart', function () {
            passwordInput.type = 'text';
        });
        passwordIcon.addEventListener('touchend', function () {
            passwordInput.type = 'password';
        });
    }
}

(function() {
	window.addEventListener('DOMContentLoaded', init);
})();
